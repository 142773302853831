<template>
  <div class="export-list-cdg">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          v-on="on"
          v-bind="attrs"
          @click="menu = true"
          class="block-icon-export-list mr-2"
        >
          <font-awesome-icon icon="download" />
        </div>
      </template>
      <span>Liste des fichiers</span>
    </v-tooltip>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      min-width="400px"
      max-width="400px"
      origin="top right"
      content-class="popover-menu-list"
    >
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content class="title-list">
              <v-list-item-title>Liste des fichiers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="line-separator-list"></v-divider>
        <LoadingComponent v-if="getLoadingListFichierExporteCdg" />
        <v-list class="box-dropdown">
          <v-list-item v-for="file in getListFichierExporteCdg" :key="file.id">
            <v-list-item-content>
              <v-row>
                <v-col cols="1">
                  <font-awesome-icon
                    class="file-icon"
                    :icon="file.file_type == 'xlsx' ? 'file-excel' : 'folder'"
                    :class="{ 'icon-excel': file.file_type == 'xlsx' }"
                  />
                </v-col>
                <v-col cols="9">
                  <span>
                    {{ file.file_name }}
                  </span>
                </v-col>
                <v-col cols="2">
                  <div
                    class="icon-close"
                    @click.prevent.stop="handleDeleteFile(file)"
                  >
                    <div
                      class="chargement chargement-loading-icon-loader"
                      v-if="file.loaderDelet"
                    >
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <font-awesome-icon :icon="'times'" v-else />
                  </div>
                </v-col>
              </v-row>
              <v-row class="row-list-export">
                <v-col cols="4">
                  <span
                    class="Plus"
                    @click.prevent.stop="handleDownloadFile(file)"
                  >
                    <Hollow-dots-spinner
                      v-if="file.loaderExport"
                      :animation-duration="1000"
                      :size="50"
                      :color="'#704ad1'"
                      class="loader loaderExportFileInList"
                    />
                    <span v-else>Télecharger</span>
                  </span>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <span class="expire-text">{{
                    computedValidation(file)
                  }}</span>
                  <span>{{ file.created_at }} </span>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="computedvoirPlus">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="2"> </v-col>
                <v-col cols="8">
                  <span
                    class="voir-plus"
                    @click.prevent.stop="handelClickMoreExcel"
                  >
                    Voir plus
                  </span>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="!getLoadingListFichierExporteCdg">
          <v-list-item
            v-if="
              getListFichierExporteCdg && getListFichierExporteCdg.length == 0
            "
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col cols="9">
                  <span class="empty-export">
                    Aucun fichier à télécharger
                  </span>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import LoadingComponent from '@/views/component/LoadingComponent';
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';
export default {
  data() {
    return { page: 1, menu: false };
  },
  computed: {
    ...mapGetters([
      'getListFichierExporteCdg',
      'getLoadingListFichierExporteCdg',
      'getErrorListFichierExporteCdg',
      'getTotalPageExcel'
    ]),
    computedvoirPlus() {
      if (this.getTotalPageExcel / 10 > this.page) {
        return true;
      } else {
        return false;
      }
    },
    computedValidation() {
      return function(file) {
        let date1 = moment(file.created_at, 'DD-MM-YYYY');
        let date2 = moment(
          moment(new Date()).format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        );
        let daysDiff = date2.diff(date1, 'days');

        let expire = 15 - daysDiff;
        if (daysDiff >= 13 && daysDiff < 15)
          return 'expire dans ' + expire + ' j';
        else if (daysDiff == 15) return "expire aujourd'hui";
        return '';
      };
    }
  },
  methods: {
    ...mapActions([
      'fecthListFichierExporteCdg',
      'deleteFileInListExporte',
      'exportFileInListExporte',
      'fetchNewListFichierExporteCdg'
    ]),
    async handleDeleteFile(file) {
      file.loaderDelet = true;
      await this.deleteFileInListExporte(file);
      file.loaderDelet = false;
    },
    async handleDownloadFile(file) {
      file.loaderExport = true;
      await this.exportFileInListExporte(file);
      file.loaderExport = false;
    },
    handelClickMoreExcel() {
      this.page = this.page + 1;
      this.fetchNewListFichierExporteCdg({ page: this.page });
    }
  },
  mounted() {
    this.fecthListFichierExporteCdg({ page: this.page });
  },
  components: {
    LoadingComponent
  }
};
</script>

<style lang="scss">
.empty-export {
  display: flex;
  text-align: center;
  justify-content: center;
}
.v-list.v-sheet.theme--light {
  margin: 0px !important;
  padding: 0px !important;
}
span {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
}
.row-list-export {
  margin-top: 0px !important;
  span {
    margin-left: 5px;
    cursor: pointer;
  }
}
.expire-text {
  color: red;
}

.loader-export-globale-cdg {
  position: absolute;
  height: 93%;
  top: -6px;
  z-index: 10;
  .loader {
    margin-left: -34%;
    width: 43px !important;
    height: 42px !important;
  }
}

.Plus {
  color: #704ad1;
  font-size: 9px;
  text-align: end;
  font-weight: 600;
  &:focus,
  &:hover {
    color: #6a40d6;
  }
}
.icon-close {
  width: 19px;
  height: 19px;
  font-size: 10px;
  padding-top: 4px;
  text-align: center;
  color: #5f6368;
  cursor: pointer;
  &:hover {
    background: #704ad1 !important;
    color: #fff;
    border-radius: 10px !important;
  }
}
.chargement-loading-icon-loader {
  margin-left: 0px !important;
  .spinner-border {
    width: 0.7rem !important;
    height: 0.7rem !important;
  }
}
.icon-excel {
  color: #96c296 !important;
  font-size: 19px;
  align-items: center;
}
.file-icon {
  color: #bc7171;
  font-size: 19px;
  align-items: center;
}
.line-separator-list {
  margin: 0px !important;
}
.title-list {
  padding: 0px !important;
  color: #2f2f2f;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 600;
}
.v-list-item__content > *:not(:last-child) {
  margin-bottom: -13px;
}
.export-list-cdg {
  button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default {
    background-color: #f1edfb;
    /* border-color: #704ad1; */
    border: 1px solid #704ad1;
    color: #704ad1;
    box-shadow: none !important;
    margin-top: 6px;
    height: 29px !important;
  }
  .v-list-item__content {
    padding: 0px !important;
  }
}
.loaderExportFileInList {
  .dot {
    width: 10px !important;
    height: 10px !important;
    margin: 0px 3.5px !important;
  }
}
.hollow-dots-spinner.loader.loaderExportFileInList {
  margin-top: -14px;
}
</style>
<style lang="scss" scoped>
.box-dropdown {
  overflow-y: auto;
  max-height: 365px;
}
.box-dropdown::-webkit-scrollbar {
  width: 10px;
}

.box-dropdown::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.box-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
.box-dropdown::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 10px;
}
</style>
